<template>
  <div class="restaurants">
    <div class="page-header">
      <h1>{{ $t('Restaurants Management') }} > {{ $t('Restaurant') }} > {{ $t('Edit') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Edit Restaurant') }}</h2>
      </div>
      <RestaurantForm @action="updateRestaurant"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Brand from '@/lib/brand';
import Restaurant from '@/lib/restaurant';
import RestaurantForm from '@/components/brand/RestaurantForm.vue';

export default {
  name: 'EditRestaurant',
  components: {
    RestaurantForm
  },
  mounted(){
    this.loadRestaurant();
  },
  methods:{
    async loadRestaurant(){
      try{
        const loginInfo = Common.getLoginInfo();
        const restaurantId = this.$route.params.id;
        const restaurant = await Restaurant.loadRestaurant(this.apiUrl, restaurantId, loginInfo);
        this.setCurrentRestaurant(restaurant);
      }catch(err){
        console.log(err);
      }
    },
    async updateRestaurant(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const restaurantId = this.$route.params.id;
        const restaurantInfo = Object.assign({}, form);
        const restaurants = await Restaurant.updateRestaurant(this.apiUrl, restaurantId, restaurantInfo, loginInfo);
        this.$router.push('/restaurants');
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentRestaurant']),
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
